    .userPlateMobile {
        width: 95dvw;
        max-height: 557px;
        border-radius: 15px;
        box-shadow: 1px 1px 7px grey;
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: column;
        word-spacing: normal;
        padding: 40px 18px;
        background-color: white;
        overflow-y: hidden;
        margin: 0 auto;
        padding-top: 20px;
    }

    .ump-info-button {
        margin-bottom: 14px;
        box-sizing: border-box;
        width: 100%;
        padding: 5px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 14px;
        align-items: center;
        border: 2px solid black;
        background-color: white;
        font-family: Fragment Mono, monospace
    }

    .ump-info-button:hover {
        cursor: pointer;
    }

    .ump-info-button-img {
        width: 28px;
        height: 28px;
    }

    .ump-info-button-text {
        text-transform: uppercase;
        font-weight: 800;
        font-size: 24px;
        font-size: 5.1dvw;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: -1.8px;
    }

    .upm-header {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .ump-colors {
        position: relative;
        display: flex;
        align-items: center;
        width: 40px;
        height: 10px;
        justify-content: space-around;
    }

    .ump-colors>div {
        border-radius: 50%;
        background-color: red;
        width: 8px;
        height: 8px;
    }

    .upm-button {
        border: solid black 2px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 3dvw;
        font-family: "Fragment Mono", monospace;
        text-transform: uppercase;
        background-color: transparent;
        border-radius: 15px;
        padding: 5px 9px;
        margin-right: 8px;
        transition: 0.3s ease-in-out;
        text-decoration: none;
        color: black;
        flex-shrink: 1;

    }

    .upm-button>span {
        text-align: center;
        width: 100%;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .upm-button:visited {
        color: black;
    }

    .upm-button>img {
        max-width: 30px;
    }

    .upm-button:hover {
        cursor: pointer;
        transform: scale(0.96);
    }

    .upm-header-title {
        width: 100%;
        font-size: 8dvw;
        font-weight: 800;
        overflow: hidden;
        max-height: 85px;
        text-transform: uppercase;
        line-height: 42px;
        margin-bottom: 10px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
    }

    .upm-Img {
        width: 100%;
        border-radius: 2px;
        margin-bottom: 14px;
        min-height: 220px;
        max-height: 220px;
        background-repeat: no-repeat;
        background-position: 22% 43%;
        background-size: 100%;
    }

    .upm-Img>img {
        width: 100%;
        border-radius: 2px;
        min-height: 220px;
        max-height: 220px;
    }

    .upm-footer {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        gap: 10px;
    }

    .upm-footerLeftCol {
        min-width: 26%;
        border-right: solid 2px black;
        font-size: 0.9em;
        text-transform: uppercase;
        word-spacing: normal;
    }

    .upm-footerLeftCol>ul {
        max-width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        padding: 0;
        padding-bottom: 4px;
    }

    .upm-footerLeftCol>ul>li {
        line-height: 27px;
        text-wrap: balance;
        width: 90%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        flex-grow: 6;
        letter-spacing: -1.8px;
    }

    .upm-footerLeftCol>ul>li>span {
        white-space: wrap;
        text-overflow: ellipsis;
        font-weight: 600;
        overflow: hidden;
        font-size: 0.95rem;
    }

    /* .upm-footerLeftCol>ul>li:last-child {
        font-size: 0.8em;
        color: gray;
    } */
    .underline {
        border-bottom: solid 2px;
    }

    .upm-footerRightCol {
        flex-grow: 2;
        max-width: 260px;
    }

    .upm-footerRightCol>ul {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .upm-footerRightCol>ul>li>span:first-child {
        font-size: 1.3em;
        line-height: 27px;
        letter-spacing: -1.8px;
        color: gray;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
    }

    .upm-footerRightCol>ul>li {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        gap: 10px;
    }

    .upm-footerRightCol>ul>li>span:last-child {
        white-space: nowrap;
        word-spacing: normal;
        padding-right: 10px;
        font-size: 1.18em;
        letter-spacing: -1px;
        line-height: 27px;
        font-weight: 800;
        word-spacing: -3px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 160px;
    }

    .keen-slider {
        margin-top: -160%;
        min-height: 180dvh;
    }