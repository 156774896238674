@import url('https://fonts.googleapis.com/css2?family=Fragment+Mono:ital@0;1&display=swap');

.stylish-regular {
  font-family: "Fragment Mono", monospace;
  font-weight: 400;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  overflow: hidden;
  height: 106dvh;
  background-color: white;
  margin-top: -40px;
}

div.skiptranslate,
iframe.skiptranslate {
  visibility: hidden !important;
}

body {
  position: relative;
  background-color: white;
  margin-top: -40px;
  overflow: hidden;
  font-family: "Fragment Mono", monospace;
}

.alterSwiper {
  margin-top: -27dvh;
  height: 135dvh;
}

.alterSwiper>.swiper-wrapper>.swiper-slide {
  min-height: 557px;
  border-radius: 20px;
}

ul {
  list-style: none;
}

.Toastify__toast-container {}

.Toastify__toast {
  border-radius: 15px;
  font-size: 4dvw;
  color: Black;
  background-color: transparent;
  backdrop-filter: blur(15px);
  font-weight: 900;
  border: solid 2px black;
}