.userPlateMobile-description {
    position: fixed;
    width: 100dvw;
    height: 100dvh;
    font-display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 20px;
    top: 0;
    left: 0;
    z-index: 22;
    backdrop-filter: blur(105px);
    overflow-y: scroll;
    scrollbar-width: none;
}

.ump-info-button-img {
    width: 28px;
    height: 28px;
}

.userPlateMobile-description-text {
    min-height: 50dvh;
    text-align: left;
    padding: 0 26px;
    font-size: 5.1dvw;
    font-weight: 800;
    letter-spacing: 0px;
    text-transform: uppercase;
    hyphens: auto;
}

.descriptionPopupCloser {
    transform: scaleX(1.5);
    font-size: 6dvw;
    font-weight: 800;
}

.userPlateMobile-description-header-controll {
    height: 50px;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 18px;
    display: flex;
    justify-content: end;
    align-items: center;
}

.ump-info-button-text {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 24px;
    font-size: 5.1dvw;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: -1.8px;
}